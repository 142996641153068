import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import constants from '@coligo-org/fe-common/constants';
import { users as userServices } from '@coligo-org/fe-common/services';
import {
  profileSettingsMessages,
  commonMessages,
} from '@coligo-org/fe-common/translations';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid2';

import { users as UsersActions } from 'reduxConfig/stateActions';
import failed from '../../../../../../common/assets/img/failed.png';
import verified from '../../../../../../common/assets/img/verified.png';
import styles from './styles';

const useStyles = makeStyles(styles);

function VerificationSuccess() {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid container direction="column" className={classes.container} size={12}>
      <img src={verified} alt="verified badge" className={classes.image} />
      <Typography variant="h1" className={classes.message}>
        {intl.formatMessage({
          ...commonMessages.verified,
        })}
      </Typography>
      <Typography variant="h4" className={classes.message}>
        {intl.formatMessage({
          ...profileSettingsMessages.accountVerifiedConfirmationMsg,
        })}
        <br />
        {intl.formatMessage({
          ...profileSettingsMessages.verifyEmailInstructionsPart1,
        })}
      </Typography>
    </Grid>
  );
}

function VerificationTokenError() {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid container direction="column" className={classes.container}>
      <img src={failed} alt="failure badge" className={classes.image} />
      <Typography variant="h1" className={classes.message}>
        {intl.formatMessage({
          ...profileSettingsMessages.invalidURLMsg,
        })}
      </Typography>
      <Typography variant="h4" className={classes.message}>
        {intl.formatMessage({
          ...profileSettingsMessages.invalidURLDetailedMsg,
        })}
        <br />
        {intl.formatMessage({
          ...profileSettingsMessages.checkEmailForURLMsg,
        })}
      </Typography>
    </Grid>
  );
}

function Confirmation() {
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);
  const [isVerificationSuccess, setIsVerificationSuccess] = useState(false);

  const user = useSelector(state => state.auth.user);

  const dispatch = useDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();

  const token = new URLSearchParams(search).get('token');
  const classes = useStyles();
  const intl = useIntl();

  useEffect(() => {
    const getUser = (...args) => dispatch(UsersActions.getUser(...args));
    const verifyEmail = async () => {
      if (!token || token.length < 30) {
        setIsTokenInvalid(true);
        return;
      }

      const body = {
        token,
        username: user.username,
      };

      try {
        await userServices.verifyEmail(body);
        setIsVerificationSuccess(true);
        getUser({});
      } catch (err) {
        switch (err.response?.data.errorCode) {
          case constants.ERROR_CODES.INVALID_TOKEN:
          case constants.ERROR_CODES.EXPIRED_TOKEN:
            setIsTokenInvalid(true);
            break;
          default:
            toast.error(err.response?.data.message);
        }
      }
    };
    verifyEmail();
  }, [dispatch, token, user.username]);

  return (
    <Paper className={classes.paper}>
      {isTokenInvalid && <VerificationTokenError />}
      {isVerificationSuccess && <VerificationSuccess />}
      <Typography
        display="block"
        className={classes.hyperLink}
        onClick={() => {
          navigate('', { replace: true });
        }}
      >
        {intl.formatMessage({
          ...profileSettingsMessages.proceedToColigoLabel,
        })}
      </Typography>
    </Paper>
  );
}

export default Confirmation;
