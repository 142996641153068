import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { miscellaneous as appService } from '@coligo-org/fe-common/services';

import { Typography } from '@mui/material';
import { Alarm } from '@mui/icons-material';
import Grid from '@mui/material/Grid2';

import useCachedService from 'hooks/useCachedService';

function CountDownTimer({
  dueTime,
  onTimerEnd,
  onTimerUpdate,
  color,
  timeEndInSeconds,
  withStyling,
}) {
  const [countDown, setCountDown] = useState('--:--:--');
  const [remainingTimeInSeconds, setRemainingTimeInSeconds] = useState();

  const classes = {
    headerTimeSection: {
      border: `1px solid ${color}`,
      borderRadius: 5,
      padding: 5,
    },
    alarmIcon: {
      color,
    },
    timeText: {
      color,
      fontWeight: 'bold',
    },
  };

  const { data: serverTime, refetch: refetchServerTime } = useCachedService({
    key: 'appService.getServerTime',
    fetcher: () => appService.getServerTime(),
    refetchOnMount: 'always',
  });

  useEffect(() => {
    if (serverTime) {
      const timeShift = serverTime.unixTime - new Date().getTime();
      const remaining = setInterval(() => {
        const now = new Date();
        setRemainingTimeInSeconds(prev => {
          const newRemainingTime =
            (dueTime - (now.getTime() + timeShift)) / 1000;
          if (Math.abs(newRemainingTime - prev) > 10) {
            refetchServerTime();
          }
          return newRemainingTime;
        });
      }, 1000);

      return () => {
        clearInterval(remaining);
      };
    }
    return () => {};
  }, [dueTime, refetchServerTime, serverTime]);

  useEffect(() => {
    const updateCountdownString = () => {
      const hours = Math.floor(remainingTimeInSeconds / 3600) % 60 || 0;
      const minutes = Math.floor(remainingTimeInSeconds / 60) % 60 || 0;
      const seconds = Math.floor(remainingTimeInSeconds) % 60 || 0;
      setCountDown(
        `${hours > 0 ? `${hours} : ` : ''}${
          minutes > 9 ? `${minutes} : ` : `0${minutes} : `
        }${seconds > 9 ? `${seconds}` : `0${seconds}`}`,
      );
    };

    if (remainingTimeInSeconds < timeEndInSeconds) {
      onTimerEnd();
    }
    updateCountdownString();
    onTimerUpdate(remainingTimeInSeconds);
  }, [onTimerEnd, onTimerUpdate, remainingTimeInSeconds, timeEndInSeconds]);

  if (!withStyling) {
    return countDown;
  }

  return (
    <Grid
      container
      spacing={1}
      style={classes.headerTimeSection}
      justifyContent="center"
    >
      <Grid>
        <Alarm style={classes.alarmIcon} />
      </Grid>
      <Grid>
        <Typography style={classes.timeText}>{countDown}</Typography>
      </Grid>
    </Grid>
  );
}

CountDownTimer.defaultProps = {
  onTimerUpdate: () => undefined,
  color: 'black',
  timeEndInSeconds: 0,
  withStyling: true,
  dueTime: 0,
};

CountDownTimer.propTypes = {
  dueTime: PropTypes.number,
  onTimerEnd: PropTypes.func.isRequired,
  onTimerUpdate: PropTypes.func,
  color: PropTypes.string,
  timeEndInSeconds: PropTypes.number,
  withStyling: PropTypes.bool,
};

export default CountDownTimer;
