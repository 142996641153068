import { combineReducers } from 'redux';

import item from './reducers/item';
import state from './reducers/state';
import courses from './reducers/courses';

export default combineReducers({
  state,
  item,
  courses,
});
